import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Header from '../Header'
import Footer from '../../Footer'
import { FaCheck } from 'react-icons/fa'
import Sidebar from '../SideBar'
import { Row, Col } from 'react-bootstrap'
import { Button, CardContent, Typography, Rating, Avatar } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { fetchTimeSlots } from '../../../api/patient'
import { getDoctorRating } from '../../../api/patient'
import full from '../../../assets/images/doctors/FULL.png'

const PatientBooking = () => {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [availableTimeSlots, setAvailableTimings] = useState([])
  const [timeSlotData, setTimeSlotData] = useState({})
  const [timeSlotID, setTimeSlotID] = useState([])
  const [averageRating, setAveragerating] = useState([])
  const navigate = useNavigate()
  const [times, setTimes] = useState({})
  const [endTime, setEndTime] = useState({})

  const location = useLocation()
  const { doctorData } = location.state
  const doctorID = doctorData._id

  const fetchSlots = async (date) => {
    try {
      const selectedDay = dayjs(date).format('YYYY-MM-DD')
      const response = await fetchTimeSlots(doctorID, selectedDay)
      const timings = response
        .filter((timeSlot) => !timeSlot.is_booked)
        .map((timeSlot) => timeSlot.start_time)
      setAvailableTimings(timings)

      const timeSlotData = response
        .filter((timeSlot) => !timeSlot.is_booked)
        .reduce((acc, timeSlot) => {
          acc[timeSlot.start_time] = timeSlot.end_time
          return acc
        }, {})
      setTimeSlotData(timeSlotData)

      const timeSlotID = response
        .filter((timeSlot) => !timeSlot.is_booked)
        .map((timeSlot) => timeSlot._id)
      setTimeSlotID(timeSlotID)
    } catch (error) {
      console.error('Error fetching available time slots:', error)
    }
  }

  const fetchRating = async () => {
    try {
      const response = await getDoctorRating(doctorID)
      const rating = response
      setAveragerating(rating)
    } catch (error) {
      console.error('Error fetching average rating:', error)
    }
  }

  fetchRating()

  const handleDateChange = (date) => {
    setSelectedDate(date)
    fetchSlots(date)
  }

  const handleTimeButtonClick = (time) => {
    const endTime = timeSlotData[time]
    setEndTime(endTime)

    setTimes((prevTimes) => ({
      ...prevTimes,
      [selectedDate]: time,
    }))
    setEndTime(endTime)
  }

  const navigateToCheckout = () => {
    const queryParams = new URLSearchParams()
    queryParams.append('date', selectedDate.toISOString())
    if (selectedDate && times[selectedDate]) {
      queryParams.append('time', times[selectedDate])

      const selectedTimeSlotId =
        timeSlotID[availableTimeSlots.indexOf(times[selectedDate])]
      queryParams.append('timeSlotId', selectedTimeSlotId)

      queryParams.append('endTime', endTime)
    }

    queryParams.append('profile', doctorData.profile_image)
    queryParams.append('rating', averageRating)
    queryParams.append('title', doctorData.title)
    queryParams.append('firstname', doctorData.firstname)
    queryParams.append('lastname', doctorData.lastname)
    queryParams.append('doctorID', doctorData.doctorID)
    queryParams.append(
      'location',
      doctorData.location_name.split(', ').slice(0, 3).join(', ')
    )

    const queryString = queryParams.toString()
    const url = `/PatientCheckout?${queryString}`

    navigate(url)
  }

  useEffect(() => {
    fetchSlots(selectedDate)
  }, [selectedDate])

  return (
    <div>
      <Header />

      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/PatientDashboard">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Book Appointment
                  </li>
                </ol>
              </nav>
              <h2 class="breadcrumb-title">Book Appointment</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container-fluid">
          <div className="row align-items-start">
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>

            <div className="col-md-7 col-lg-8 col-xl-9">
              <div
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #ededed',
                  borderRadius: 15,
                  padding: '20px',
                }}
              >
                <Row>
                  {' '}
                  <div style={{ marginLeft: '30px' }}>
                    <Avatar sx={{ width: 150, height: 150 }}>
                      <img
                        src={doctorData.profile_image}
                        alt="Doctor Name"
                        style={{
                          width: '150%',
                          height: '100%',
                        }}
                      />
                    </Avatar>
                  </div>
                  <Col>
                    <Typography gutterBottom variant="h5" component="div">
                      {` ${doctorData.title} ${doctorData.firstname} ${doctorData.lastname}`}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <Rating
                        name="half-rating"
                        value={averageRating}
                        precision={0.5}
                        readOnly
                      />
                    </Typography>
                    <Typography>
                      <LocationOnIcon />{' '}
                      {doctorData.location_name
                        .split(', ')
                        .slice(0, 3)
                        .join(', ')}
                    </Typography>
                  </Col>
                </Row>
                <CardContent>
                  {' '}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div style={{ marginTop: 20, borderRadius: 5 }}>
                      <DatePicker
                        defaultValue={dayjs(selectedDate)}
                        format="DD/MM/YYYY"
                        selected={selectedDate}
                        onChange={handleDateChange}
                      />
                    </div>
                  </LocalizationProvider>
                </CardContent>

                <div>
                  {availableTimeSlots.length > 0 ? (
                    availableTimeSlots.map((time, index) => (
                      <Button
                        style={{
                          marginTop: 10,
                          marginRight: 10,
                          backgroundColor:
                            selectedDate && times[selectedDate] === time
                              ? '#42c0fb'
                              : '#e8e8e8',

                          color:
                            selectedDate && times[selectedDate] === time
                              ? '#fff'
                              : '#575a5d',
                          fontSize: '15px',
                          padding: 10,
                          width: 130,
                        }}
                        key={index}
                        variant="primary"
                        onClick={() => handleTimeButtonClick(time)}
                      >
                        {selectedDate && times[selectedDate] === time && (
                          <FaCheck color="white" style={{ marginRight: 5 }} />
                        )}{' '}
                        {time}
                      </Button>
                    ))
                  ) : (
                    <div style={{ marginLeft: '30%' }}>
                      <img
                        src={full}
                        alt="Full"
                        style={{
                          width: '70%',
                          height: '70%',
                          marginLeft: '-15%',
                        }}
                      />
                    </div>
                  )}
                </div>
                {availableTimeSlots.length > 0 && (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#15558d',
                      marginTop: 10,
                      marginBottom: 30,
                      width: 150,
                    }}
                    onClick={navigateToCheckout}
                  >
                    CONTINUE
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PatientBooking
