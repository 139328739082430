import React from 'react'
import '../../../App.css'
import Header from '../Header'
import Footer from '../../Footer'
import Sidebar from '../SideBar'
import { useNavigate } from 'react-router-dom'

import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Grid,
  TableRow,
} from '@mui/material'
import doctorimage from '../../../assets/images/doctors/doctor-03.jpg'
import StarIcon from '@mui/icons-material/Star'
import LocationIcon from '../../../assets/images/icons/location.svg'
import MoneyIcon from '../../../assets/images/icons/money.svg'
import AvailabeIcon from '../../../assets/images/icons/available.svg'
import FavIcon from '../../../assets/images/icons/fav.svg'

const PatientFavourites = () => {
  const navigate = useNavigate()

  const handleViewProfileClick = () => {
    navigate('/DoctorProfile')
  }

  const handleBookAppointmentClick = () => {
    navigate('/PatientBooking')
  }
  return (
    <div>
      <Header />

      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/PatientDashboard">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Favourites
                  </li>
                </ol>
              </nav>
              <h2 class="breadcrumb-title">Favourites</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 ">
              <Sidebar />
            </div>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Card
                    style={{
                      width: '100%',
                      maxWidth: '318px',
                      margin: '0 auto 15px auto',
                    }}
                  >
                    <CardContent>
                      <CardMedia
                        component="img"
                        alt="Doctor Image"
                        height="193px"
                        width="100%"
                        src={doctorimage}
                      />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '8px',
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: '#15558D',
                            marginTop: 4,
                          }}
                        >
                          Dr. Ruby Perrin
                        </Typography>
                        <img
                          src={FavIcon}
                          alt="Favorite Icon"
                          style={{
                            marginLeft: '10px',
                            width: '16px',
                            height: '16px',
                          }}
                        />
                      </div>
                      <Typography
                        variant="body2"
                        style={{
                          color: '#77c11f',
                          fontSize: 17,
                          marginBottom: 5,
                        }}
                      >
                        Obstetrics and Gynecology
                      </Typography>
                      <TableRow>
                        <StarIcon style={{ color: 'gold' }} />
                        <StarIcon style={{ color: 'gold' }} />
                        <StarIcon style={{ color: 'gold' }} />
                        <StarIcon style={{ color: 'gold' }} />
                        <StarIcon style={{ color: 'gold' }} />
                      </TableRow>
                      <div style={{ marginBottom: '8px' }}></div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '8px',
                        }}
                      >
                        <img
                          src={LocationIcon}
                          alt="Location"
                          style={{
                            marginRight: '4px',
                            width: '14px',
                            height: '14px',
                            filter: 'grayscale(1)',
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{ color: 'grey', fontSize: 13 }}
                        >
                          Beirut, Forn El Chebbak
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '8px',
                        }}
                      >
                        <img
                          src={AvailabeIcon}
                          alt="Availability"
                          style={{
                            marginRight: '4px',
                            width: '14px',
                            height: '14px',
                            filter: 'grayscale(1)',
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{ color: 'grey', fontSize: 13 }}
                        >
                          Available on Fri, 22 Mar
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '8px',
                        }}
                      >
                        <img
                          src={MoneyIcon}
                          alt="Money"
                          style={{
                            marginRight: '4px',
                            width: '14px',
                            height: '14px',
                            filter: 'grayscale(1)',
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{ color: 'grey', fontSize: 13 }}
                        >
                          $30-$100
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '16px',
                        }}
                      >
                        <Button
                          onClick={handleBookAppointmentClick}
                          variant="contained"
                          style={{
                            width: '48%',
                            backgroundColor: '#0DE0FE',
                            color: 'white',
                            fontWeight: 'bolder',
                          }}
                        >
                          Book Now
                        </Button>
                        <Button
                          onClick={handleViewProfileClick}
                          variant="contained"
                          style={{
                            width: '48%',
                            backgroundColor: '#FFFFFF',
                            color: '#0DE0FE',
                            borderColor: '#0DE0FE',
                            fontWeight: 'bolder',
                          }}
                        >
                          View Profile
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card
                    style={{
                      width: '100%',
                      maxWidth: '318px',
                      margin: '0 auto 15px auto',
                    }}
                  >
                    <CardContent>
                      <CardMedia
                        component="img"
                        alt="Doctor Image"
                        height="193px"
                        width="100%"
                        src={doctorimage}
                      />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '8px',
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: '#15558D',
                            marginTop: 4,
                          }}
                        >
                          Dr. Ruby Perrin
                        </Typography>
                        <img
                          src={FavIcon}
                          alt="Favorite Icon"
                          style={{
                            marginLeft: '10px',
                            width: '16px',
                            height: '16px',
                          }}
                        />
                      </div>
                      <Typography
                        variant="body2"
                        style={{
                          color: '#77c11f',
                          fontSize: 17,
                          marginBottom: 5,
                        }}
                      >
                        Obstetrics and Gynecology
                      </Typography>
                      <TableRow>
                        <StarIcon style={{ color: 'gold' }} />
                        <StarIcon style={{ color: 'gold' }} />
                        <StarIcon style={{ color: 'gold' }} />
                        <StarIcon style={{ color: 'gold' }} />
                        <StarIcon style={{ color: 'gold' }} />
                      </TableRow>
                      <div style={{ marginBottom: '8px' }}></div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '8px',
                        }}
                      >
                        <img
                          src={LocationIcon}
                          alt="Location"
                          style={{
                            marginRight: '4px',
                            width: '14px',
                            height: '14px',
                            filter: 'grayscale(1)',
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{ color: 'grey', fontSize: 13 }}
                        >
                          Beirut, Forn El Chebbak
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '8px',
                        }}
                      >
                        <img
                          src={AvailabeIcon}
                          alt="Availability"
                          style={{
                            marginRight: '4px',
                            width: '14px',
                            height: '14px',
                            filter: 'grayscale(1)',
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{ color: 'grey', fontSize: 13 }}
                        >
                          Available on Fri, 22 Mar
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '8px',
                        }}
                      >
                        <img
                          src={MoneyIcon}
                          alt="Money"
                          style={{
                            marginRight: '4px',
                            width: '14px',
                            height: '14px',
                            filter: 'grayscale(1)',
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{ color: 'grey', fontSize: 13 }}
                        >
                          $30-$100
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '16px',
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            width: '48%',
                            backgroundColor: '#0DE0FE',
                            color: 'white',
                            fontWeight: 'bolder',
                          }}
                        >
                          Book Now
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            width: '48%',
                            backgroundColor: '#FFFFFF',
                            color: '#0DE0FE',
                            borderColor: '#0DE0FE',
                            fontWeight: 'bolder',
                          }}
                        >
                          View Profile
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card
                    style={{
                      width: '100%',
                      maxWidth: '318px',
                      margin: '0 auto 15px auto',
                    }}
                  >
                    <CardContent>
                      <CardMedia
                        component="img"
                        alt="Doctor Image"
                        height="193px"
                        width="100%"
                        src={doctorimage}
                      />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '8px',
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: '#15558D',
                            marginTop: 4,
                          }}
                        >
                          Dr. Ruby Perrin
                        </Typography>
                        <img
                          src={FavIcon}
                          alt="Favorite Icon"
                          style={{
                            marginLeft: '10px',
                            width: '16px',
                            height: '16px',
                          }}
                        />
                      </div>
                      <Typography
                        variant="body2"
                        style={{
                          color: '#77c11f',
                          fontSize: 17,
                          marginBottom: 5,
                        }}
                      >
                        Obstetrics and Gynecology
                      </Typography>
                      <TableRow>
                        <StarIcon style={{ color: 'gold' }} />
                        <StarIcon style={{ color: 'gold' }} />
                        <StarIcon style={{ color: 'gold' }} />
                        <StarIcon style={{ color: 'gold' }} />
                        <StarIcon style={{ color: 'gold' }} />
                      </TableRow>
                      <div style={{ marginBottom: '8px' }}></div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '8px',
                        }}
                      >
                        <img
                          src={LocationIcon}
                          alt="Location"
                          style={{
                            marginRight: '4px',
                            width: '14px',
                            height: '14px',
                            filter: 'grayscale(1)',
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{ color: 'grey', fontSize: 13 }}
                        >
                          Beirut, Forn El Chebbak
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '8px',
                        }}
                      >
                        <img
                          src={AvailabeIcon}
                          alt="Availability"
                          style={{
                            marginRight: '4px',
                            width: '14px',
                            height: '14px',
                            filter: 'grayscale(1)',
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{ color: 'grey', fontSize: 13 }}
                        >
                          Available on Fri, 22 Mar
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '8px',
                        }}
                      >
                        <img
                          src={MoneyIcon}
                          alt="Money"
                          style={{
                            marginRight: '4px',
                            width: '14px',
                            height: '14px',
                            filter: 'grayscale(1)',
                          }}
                        />
                        <Typography
                          variant="body2"
                          style={{ color: 'grey', fontSize: 13 }}
                        >
                          $30-$100
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '16px',
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            width: '48%',
                            backgroundColor: '#0DE0FE',
                            color: 'white',
                            fontWeight: 'bolder',
                          }}
                        >
                          Book Now
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            width: '48%',
                            backgroundColor: '#FFFFFF',
                            color: '#0DE0FE',
                            borderColor: '#0DE0FE',
                            fontWeight: 'bolder',
                          }}
                        >
                          View Profile
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default PatientFavourites
