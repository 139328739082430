import React, { useEffect, useState } from 'react'
import Header from '../Header'
import Footer from '../../Footer'
import Sidebar from '../SideBar'
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Grid,
  Rating,
} from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import LocationIcon from '../../../assets/images/icons/location.svg'
import MoneyIcon from '../../../assets/images/icons/money.svg'
import AvailabeIcon from '../../../assets/images/icons/available.svg'
import { useNavigate } from 'react-router-dom'

import { fetchAllDoctors, fetchNearestTimeSlot } from '../../../api/doctor'
import { getDoctorRating } from '../../../api/patient'

const AllDoctors = () => {
  const [doctors, setDoctors] = useState([])
  const [nearestTimeSlotDate, setNearestTimeSlotDate] = useState(null)
  const [averageRatings, setAverageRatings] = useState({})
  const navigate = useNavigate()

  useEffect(() => {
    async function fetchData() {
      try {
        const allDoctors = await fetchAllDoctors()
        setDoctors(allDoctors)
        const nearestTimeSlot = await fetchNearestTimeSlot()
        if (nearestTimeSlot) {
          setNearestTimeSlotDate(new Date(nearestTimeSlot.date))
        }
        for (const doctor of allDoctors) {
          const rating = await getDoctorRating(doctor._id)
          setAverageRatings((prevRatings) => ({
            ...prevRatings,
            [doctor._id]: rating,
          }))
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  const handleViewProfileClick = () => {
    navigate(`/doctorProfile/`)
  }

  const handleBookAppointmentClick = () => {
    navigate(`/PatientBooking/`)
  }

  return (
    <div>
      <Header />

      {/* Breadcrumb */}
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/PatientDashboard">Home</a>
                  </li>
                  {/* You should change the breadcrumb Title */}
                  <li class="breadcrumb-item active" aria-current="page">
                    All Doctors
                  </li>
                </ol>
              </nav>
              {/* You should change the breadcrumb Title */}
              <h2 class="breadcrumb-title">All Doctors</h2>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb */}

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3">
              <Sidebar />
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <Grid container spacing={2}>
                {doctors.map((doctor) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={doctor._id}>
                    <Card
                      style={{ width: '310px', margin: '0 auto 15px auto' }}
                    >
                      <CardContent>
                        <CardMedia
                          component="img"
                          alt="Doctor Image"
                          height="193px"
                          width="100%"
                          src={doctor.profile_image}
                        />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '8px',
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              fontSize: 16,
                              fontWeight: 'bold',
                              color: '#15558D',
                              marginTop: 4,
                            }}
                          >
                            {doctor.title} {doctor.firstname} {doctor.lastname}
                          </Typography>
                        </div>
                        <Typography
                          variant="body2"
                          style={{
                            color: '#77c11f',
                            fontSize: 17,
                            marginBottom: 5,
                          }}
                        >
                          {doctor.specializations.join(', ')}
                        </Typography>
                        <div>
                          <Rating
                            name="half-rating"
                            value={averageRatings[doctor._id] || 0}
                            precision={0.5}
                            readOnly
                          />
                        </div>
                        <div style={{ marginBottom: '8px' }}></div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '8px',
                          }}
                        >
                          <img
                            src={LocationIcon}
                            alt="Location"
                            style={{
                              marginRight: '4px',
                              width: '14px',
                              height: '14px',
                              filter: 'grayscale(1)',
                            }}
                          />
                          <Typography
                            variant="body2"
                            style={{ color: 'grey', fontSize: 13 }}
                          >
                            {doctor.location_name
                              .split(' ')
                              .slice(0, 6)
                              .join(' ')}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '8px',
                          }}
                        >
                          <img
                            src={AvailabeIcon}
                            alt="Availability"
                            style={{
                              marginRight: '4px',
                              width: '14px',
                              height: '14px',
                              filter: 'grayscale(1)',
                            }}
                          />
                          <Typography
                            variant="body2"
                            style={{ color: 'grey', fontSize: 13 }}
                          >
                            Available on{' '}
                            {nearestTimeSlotDate
                              ? nearestTimeSlotDate.toDateString()
                              : 'N/A'}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '8px',
                          }}
                        >
                          <img
                            src={MoneyIcon}
                            alt="Money"
                            style={{
                              marginRight: '4px',
                              width: '14px',
                              height: '14px',
                              filter: 'grayscale(1)',
                            }}
                          />
                          <Typography
                            variant="body2"
                            style={{ color: 'grey', fontSize: 13 }}
                          >
                            {doctor.consultation_fee}$
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '16px',
                          }}
                        >
                          <Button
                            onClick={handleBookAppointmentClick}
                            variant="contained"
                            style={{
                              width: '48%',
                              backgroundColor: '#0DE0FE',
                              color: 'white',

                              fontWeight: 'bolder',
                            }}
                          >
                            Book Now
                          </Button>
                          <Button
                            onClick={handleViewProfileClick}
                            variant="contained"
                            style={{
                              width: '53%',
                              backgroundColor: '#FFFFFF',
                              color: '#0DE0FE',
                              marginLeft: '5px',
                              borderColor: '#0DE0FE',
                              fontWeight: 'bolder',
                            }}
                          >
                            View Profile
                          </Button>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AllDoctors
